import React from "react";
import "../css/styles.css";

function Footer() {
    return(
    <footer>
        <p>
            Eloi 2023
        </p>
    </footer>
    );
}

export default Footer;